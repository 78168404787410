import {
  USER_DATA,
  IS_DARK,
  ACCESS_MODULE,
  IS_TOGGLE,
  SIDEBAR_TOGGLE,
  DELETE_LENGTH,
  SIDEBAR_TOGGLE_RESP,
  IS_MODAL,
  PAGE_COUNT,
  MAIN_CLIENT_ID,
  MAIN_COURSE_ID,
  FIRST_MENU_ACTIVE,
  SECOND_MENU_ACTIVE,
  COURSE_ACTIVE,
  ASSESS_ID,
  INTERACTID,
  LANGUAGEID,
  COURSEID,
  CATEGORYIDO,
  COURSEMENU,
  LANGUAGEIDOOO,
  ACCESS_TOKEN,
  COURSELANGUAGEID,
  COURSEMASTERID,
  COURSETYPE,
  EPISODEDATA,
  FEEDBACKTYPE,
  ASSESSOBJ,
  INTEROBj,
  ERROROBJ,
  ERRORPOPMSG,
  PROFILEUPDATE,
  PROFILEDATA,
  SUCCESS_POP,
  CLIENTCOURSEID,
} from "./actionTypes";

const setUserData = (data) => {
  return {
    type: USER_DATA,
    data,
  };
};
const setIsDark = (data) => {
  return {
    type: IS_DARK,
    data,
  };
};
const setIsAccessModule = (data) => {
  return {
    type: ACCESS_MODULE,
    data,
  };
};
const setIsToggle = (data) => {
  return {
    type: IS_TOGGLE,
    data,
  };
};

const setSidebarToggle = (data) => {
  return {
    type: SIDEBAR_TOGGLE,
    data,
  };
};
const setTableLength = (data) => {
  return {
    type: DELETE_LENGTH,
    data,
  };
};
const setSidebarIsOpens = (data) => {
  return {
    type: SIDEBAR_TOGGLE_RESP,
    data,
  };
};

const setIsModal = (data) => {
  return {
    type: IS_MODAL,
    data,
  };
};
const setPageCount = (data) => {
  return {
    type: PAGE_COUNT,
    data,
  };
};
const setMainClientId = (data) => {
  return {
    type: MAIN_CLIENT_ID,
    data,
  };
};
const setMainCourseId = (data) => {
  return {
    type: MAIN_COURSE_ID,
    data,
  };
};
const setFirstMenu = (data) => {
  return {
    type: FIRST_MENU_ACTIVE,
    data,
  };
};
const setSecondMenu = (data) => {
  return {
    type: SECOND_MENU_ACTIVE,
    data,
  };
};
const setCourseActive = (data) => {
  return {
    type: COURSE_ACTIVE,
    data,
  };
};
const setAssessmentId = (data) => {
  return {
    type: ASSESS_ID,
    data,
  };
};
const setInterActiveId = (data) => {
  return {
    type: INTERACTID,
    data,
  };
};
const setLanguageIdo = (data) => {
  return {
    type: LANGUAGEID,
    data,
  };
};
const setCourseIdo = (data) => {
  return {
    type: COURSEID,
    data,
  };
};
const setCategoryIdo = (data) => {
  return {
    type: CATEGORYIDO,
    data,
  };
};
const setCourseMenu = (data) => {
  return {
    type: COURSEMENU,
    data,
  };
};
const setLanguageIdooo = (data) => {
  return {
    type: LANGUAGEIDOOO,
    data,
  };
};
const setAccessToken = (data) => {
  return {
    type: ACCESS_TOKEN,
    data,
  };
};
const setCourseLangId = (data) => {
  return {
    type: COURSELANGUAGEID,
    data,
  };
};
const setCourseMasterId = (data) => {
  return {
    type: COURSEMASTERID,
    data,
  };
};
const setCourseTypo = (data) => {
  return {
    type: COURSETYPE,
    data,
  };
};
const setEpisodeDatao = (data) => {
  return {
    type: EPISODEDATA,
    data,
  };
};
const setFeedbackType = (data) => {
  return {
    type: FEEDBACKTYPE,
    data,
  };
};
const setAssessObjo = (data) => {
  return {
    type: ASSESSOBJ,
    data,
  };
};
const setInterObjo = (data) => {
  return {
    type: INTEROBj,
    data,
  };
};
const setErrorPop = (data) => {
  return {
    type: ERROROBJ,
    data,
  };
};
const setErrorPopMsg = (data) => {
  return {
    type: ERRORPOPMSG,
    data,
  };
};
const setUpdateProfile = (data) => {
  return {
    type: PROFILEUPDATE,
    data,
  };
};
const setProfileData = (data) => {
  return {
    type: PROFILEDATA,
    data,
  };
};
const setSuccessPop = (data) => {
  return {
    type: SUCCESS_POP,
    data,
  };
};
const setClientCourseIdL = (data) => {
  return {
    type: CLIENTCOURSEID,
    data,
  };
};
export {
  setUserData,
  setIsDark,
  setIsAccessModule,
  setIsToggle,
  setSidebarToggle,
  setTableLength,
  setSidebarIsOpens,
  setIsModal,
  setPageCount,
  setMainClientId,
  setMainCourseId,
  setFirstMenu,
  setSecondMenu,
  setCourseActive,
  setAssessmentId,
  setInterActiveId,
  setLanguageIdo,
  setCourseIdo,
  setCategoryIdo,
  setCourseMenu,
  setLanguageIdooo,
  setAccessToken,
  setCourseLangId,
  setCourseMasterId,
  setCourseTypo,
  setEpisodeDatao,
  setFeedbackType,
  setAssessObjo,
  setInterObjo,
  setErrorPop,
  setErrorPopMsg,
  setUpdateProfile,
  setProfileData,
  setSuccessPop,
  setClientCourseIdL
};
