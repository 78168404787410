import styled, { css } from "styled-components/macro";
import downArrowIcon from "../../../assets/images/downArrow.png";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../../../utils/StylePropertiy";

export const FormBox = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  /* overflow-x: hidden !important; */
  /* justify-content: center;
    align-items: center; */
`;
export const InnerBox = styled.div`
  width: 100%;
  display: flex;

  /* height: 100vh; */
  flex-direction: column;
  /* align-items: center; */
  padding: 1rem;
  @media (max-width: 769px) {
    padding: 0.6rem;
  }
`;
export const InnerBoxs = styled.div`
  width: 100%;
  display: flex;

  /* height: 100vh; */
  /* align-items: center; */
  @media (max-width: 769px) {
    padding: 0;
  }
`;
export const InnerBoxReport = styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  margin-top: 1rem;

  /* height: 100vh; */
  /* align-items: center; */
  @media (max-width: 769px) {
    padding: 0;
  }
`;
export const InputBoxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;
export const CustomTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
// export
export const InputBoxContainerBottomBorder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;
export const InputBoxContainerQuiz = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;
export const InputBoxContainerTrailer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1.5rem;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;
export const InputBoxContainers = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const InputBoxContainersCerti = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 446px) {
    width: 200%;
  }
  @media (max-width: 395px) {
    width: 300%;
  }
`;
export const InputBoxContainerBorder = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 8px;
  flex-wrap: wrap;
  border: 1px solid #edeef1;
  margin-top: 10px;
`;
export const InputDragRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;

  img {
    height: 10px;
    width: 20px;
    object-fit: contain;
    cursor: grab;
  }
`;
export const InputBox = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "30%")};
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  img {
    height: 17px;
    width: 17px;
    margin-left: 8px;
    object-fit: contain;
  }

  .otherBox {
    width: 100%;
    display: flex;
    align-items: center;
  }

  @media (max-width: 769px) {
    width: 100% !important;
  }
`;
export const ColumnBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const InputBoxType = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "30%")};
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  img {
    height: 17px;
    width: 17px;
    margin-left: 8px;
    object-fit: contain;
  }

  .otherBox {
    width: 100%;
    display: flex;
    align-items: center;
  }

  @media (max-width: 769px) {
    width: 30%;
  }
`;
export const InputBoxTypeFeed = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "30%")};
  display: flex;
  flex-direction: column;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  img {
    height: 17px;
    width: 17px;
    margin-left: 8px;
    object-fit: contain;
  }

  .otherBox {
    width: 100%;
    display: flex;
    align-items: center;
  }

  @media (max-width: 769px) {
    width: 30%;
  }
`;
export const InputBoxQuiz = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "30%")};
  display: flex;
  flex-direction: column;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  img {
    height: 17px;
    width: 17px;
    margin-left: 8px;
    object-fit: contain;
  }

  .otherBox {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
export const InputBoxFilter = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "30%")};
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  img {
    height: 17px;
    width: 17px;
    margin-left: 8px;
    object-fit: contain;
  }

  .otherBox {
    width: 100%;
    display: flex;
    align-items: center;
  }

  /* @media (max-width: 769px) {
    width: 100% !important;
  } */
`;
export const InputBoxss = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "30%")};
  display: flex;
  flex-direction: column;

  @media (max-width: 769px) {
    width: 100% !important;
  }
`;
export const TagsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
export const TagsBox = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#f6f7f9")};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid transparent"};
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-size: 0.8rem;
  padding: 5px 7px;
  font-weight: 500;
  margin-right: 7px;
  border-radius: 4px;

  img {
    cursor: pointer;
    height: 10px;
    width: 10px;
    object-fit: contain;
  }
`;
export const InputBoxs = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "30%")};
  margin-left: ${(props) => (props.marGin ? props.marGin : "0")};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1269px) {
    width: 92%;
  }
  @media (max-width: 1090px) {
    width: 90%;
  }
  @media (max-width: 912px) {
    width: 88%;
  }
  @media (max-width: 810px) {
    width: 86%;
  }
  @media (max-width: 460px) {
    width: 84%;
  }
  @media (max-width: 386px) {
    width: 82%;
  }
  @media (max-width: 340px) {
    width: 80%;
  }
`;
export const InputBoxsDesc = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "30%")};
  margin-left: ${(props) => (props.marGin ? props.marGin : "0")};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1269px) {
    width: 92%;
  }
  @media (max-width: 1090px) {
    width: 90%;
  }
  @media (max-width: 912px) {
    width: 88%;
  }
  @media (max-width: 810px) {
    width: 86%;
  }
  @media (max-width: 460px) {
    width: 84%;
  }
  @media (max-width: 386px) {
    width: 82%;
  }
  @media (max-width: 340px) {
    width: 80%;
  }
`;
export const FormTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  margin-bottom: 1.7rem;
  margin-top: 1.5rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-family: "EB Garamond", serif;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  padding-bottom: 0.7rem;
  text-transform: capitalize;
`;
export const FormBoldText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: 600;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  padding-bottom: 0.5rem;
  text-transform: capitalize;
`;
export const FormBoldTexts = styled.div`
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-weight: 600;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  padding-bottom: 0.5rem;
  text-transform: capitalize;
`;
export const FromDesc = styled.div`
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "black")};
`;

export const SubmitButton = styled.button`
  background-color: #264b5d;
  border-radius: 5px;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  color: #ffffff;
  /* width: 100%; */
  outline: none;
  border: none;
  padding: 0.6rem 1rem;
  margin: 1.6rem 0;
  margin-right: 0.5rem;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};

  @media (max-width: 351px) {
    font-size: 1rem;
  }

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  :hover {
    background-color: #417690;
  }
`;
export const SaveButton = styled.button`
  background-color: #f6591c;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ffffff;
  /* width: 100%; */
  outline: none;
  border: none;
  padding: 0.6rem 1rem;
  margin: 1rem 0;
  margin-right: 0.5rem;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  /* @media (max-width: 351px) {
    font-size: 1rem;
  } */

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  :hover {
    background-color: #417690;
  }
`;
export const SaveButtonTwo = styled.div`
  background-color: #f6591c;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ffffff;
  /* width: 100%; */
  outline: none;
  border: none;
  padding: 0.6rem 1rem;
  margin: 1rem 0;
  margin-right: 0.5rem;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  /* @media (max-width: 351px) {
    font-size: 1rem;
  } */

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  :hover {
    background-color: #417690;
  }
`;
export const CancelButton = styled.div`
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  border-radius: 8px;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#ffffff" : "#464c5e")};
  font-weight: 600;
  /* width: 100%; */
  outline: none;
  padding: 0.6rem 1rem;
  margin: 1rem 0;
  margin-right: 0.5rem;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  /* @media (max-width: 351px) {
    font-size: 1rem;
  } */

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  /* :hover {
    background-color: #417690;
  } */
`;
export const CancelButtonTwo = styled.button`
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  border-radius: 8px;
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#ffffff" : "#464c5e")};
  font-weight: 600;
  /* width: 100%; */
  outline: none;
  padding: 0.6rem 1rem;
  margin: 1rem 0;
  margin-right: 0.5rem;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  /* @media (max-width: 351px) {
    font-size: 1rem;
  } */

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  /* :hover {
    background-color: #417690;
  } */
`;

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  gap: 0.7rem;
  margin-top: 1rem;
  /* background-color: #212121; */
`;
export const ButtonGroupss = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.3rem;
  /* background-color: #212121; */
`;
export const ButtonGroupssBulk = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  @media (max-width: 769px) {
    width: 100%;
  }
  /* background-color: #212121; */
`;
export const ButtonGroups = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: #212121; */
`;
export const BlueRowBox = styled.div`
  width: 100%;
  padding: 1rem;
  color: white;
  background-color: #264b5d;
  font-size: 0.8rem;
  margin: 1rem 0;
  border-radius: 8px;
`;
export const CustomCheckBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #262626;
  padding: 0.5rem 0;
`;
export const CheckRow = styled.div`
  width: 100%;
  display: flex;
`;
export const CheckLable = styled.label`
  font-size: 0.9rem;
  /* color: black; */
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  margin-left: 0.4rem;
  margin-bottom: 0.3rem;
`;
export const CheckDesc = styled.div`
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "black")};
`;
export const PermissionBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  padding: 0.3rem;
  border: ${(props) =>
    props.error || props.erroros
      ? "1px solid red"
      : props.themeColor
      ? "1px solid transparent"
      : "1px solid transparent"};
  border-radius: 8px;

  @media (max-width: 769px) {
    padding: 0;
    border: 0;
    flex-direction: column;
  }
`;
export const ChooseFilter = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
  text-transform: none !important;
  @media (max-width: 769px) {
    width: 100%;
  }
`;
export const InnerChooseFilter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* border: 1px solid lightslategrey; */
  border-radius: 8px;
`;
export const ChooseHeader = styled.div`
  width: 100%;
  padding: 1rem;
  color: ${(props) =>
    props.activeOrange
      ? props.themeColor
        ? "#F05D24"
        : "#F6591C"
      : props.themeColor
      ? "#CECFD2"
      : "#667085"};
  font-size: 0.8rem;
  background-color: ${(props) => (props.themeColor ? "#1F242F" : "#F6F7F9")};
  border-radius: 8px 8px 0 0;
  display: flex;
  font-weight: 400;
  align-items: center;
  text-transform: capitalize;
  i {
    color: white;
    font-size: 1.3rem;
    margin-left: 5px;
  }
`;
export const FilterRow = styled.div`
  width: 100%;
  display: flex;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #EDEEF1"};
  padding: 8px 5px;
  align-items: center;
  img {
    height: 15px;
    width: 15px;
    margin-left: 0.5rem;
  }
`;
export const FilterBox = styled.input`
  width: 93%;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
  outline: none;
  background-color: transparent;
  color: ${(props) => (props.themeColor ? "#FFFFFF" : "#161b26")};

  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  }
`;
export const FilterListBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3px 0;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #EDEEF1"};
  border-radius: 0 0 8px 8px;
  text-transform: none !important;

  ::-webkit-scrollbar-thumb {
    background-color: #edeef1;
    /* Color of the thumb */
    /* Rounded corners for the thumb */
  }
`;
export const FilterList = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  font-size: 0.8rem;
  margin: 1px 0;
  font-weight: 500;
  padding: 10px 10px;
  background-color: ${(props) => props.activeColor};
  cursor: pointer;
  text-transform: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
`;
export const ArrowBox = styled.div`
  /* width: 6%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 769px) {
    margin: 1rem 0;
    flex-direction: row;
  }
`;
export const ComArrow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
  }
`;
export const NoData = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin: auto;
`;
export const AllSelectionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #363a44;
  font-size: 0.8rem;
  margin-top: 1rem;
  font-weight: 500;
  cursor: pointer;
  gap: 0.5rem;

  img {
    height: 10px;
    width: 10px;
    object-fit: contain;
  }
`;
export const HeadingRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none !important;
  }
`;
export const FirstHeading = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  font-weight: 500;
  text-transform: capitalize;
  font-size: 0.8rem;
  color: ${(props) =>
    props.themeColor ? "#CECFD2 !important" : "#667085 !important"};

  img {
    height: 10px;
    width: 10px;
    object-fit: contain;
    margin: 0 0.8rem;
  }
`;
export const SecondHeading = styled.div`
  padding: 5px 8px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.themeColor ? "#333741 !important" : "#edeef1 !important"};
  font-weight: 600;
  font-size: 0.8rem;
  color: ${(props) =>
    props.themeColor ? "#CECFD2 !important" : "#464C5E !important"};
  text-transform: capitalize;
`;
export const SecondHeadingMo = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
  padding: 5px 8px;
  border-radius: 6px;
  color: ${(props) =>
    props.themeColor ? "#CECFD2 !important" : "#464C5E !important"};
  background-color: ${(props) =>
    props.themeColor ? "#333741 !important" : "#edeef1 !important"};
  text-transform: capitalize;
`;
export const QuestionContainer = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    /* padding: 0.7rem; */
  }
  @media (max-width: 769px) {
    padding: 1rem;
  }
`;
export const FormContainerBulk = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    /* padding: 0.7rem; */
  }
  @media (max-width: 769px) {
    padding: 1rem;
    background-color: transparent;
  }
`;
export const FormContainerComit = styled.div`
  max-height: 600px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    /* padding: 0.7rem; */
  }
  @media (max-width: 769px) {
    padding: 1rem;
  }
`;
export const FormContainerLog = styled.div`
  /* max-height: 500px; */
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  /* border: 1px solid yellow; */

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    /* padding: 0.7rem; */
  }
  @media (max-width: 769px) {
    padding: 1rem;
  }
`;
export const CertiRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #EDEEF1"};
`;
export const CertiFirst = styled.div`
  width: 50%;
  display: flex;
  justify-content: left;
  padding: 0.8rem 1.5rem;
  align-items: center;
  border-right: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #EDEEF1"};
  p {
    font-weight: 500;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
  }
`;
export const CertiSecond = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 15px;
    width: 18px;
    object-fit: contain;
  }
`;
export const FormContainerBlue = styled.div`
  height: 20px;
  width: 106%;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#161b26" : "#F6F7F9")};
  position: absolute;
  margin-left: -2rem;
  margin-top: ${(props) => props.marTop};

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    /* padding: 0.7rem; */
  }
  @media (max-width: 769px) {
    padding: 1rem;
  }
`;
export const FormContainerPopup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#ffffff")};
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    /* padding: 0.7rem; */
  }
  @media (max-width: 769px) {
    padding: 1rem;
  }
`;
export const FormContainerTrailer = styled.div`
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#ffffff")};
  border-radius: 8px 8px 0 0;
  padding: 1.5rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
`;
export const FormContainerLearner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    padding: 0.7rem;
  }
  @media (max-width: 520px) {
    padding: 0.4rem;
  }
`;
export const FormContainerClient = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    padding: 0.7rem;
  }
  @media (max-width: 520px) {
    padding: 0.4rem;
  }
`;
export const FormContainerBorder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background-color: transparent;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    padding: 0.7rem;
  }
  @media (max-width: 520px) {
    padding: 0.4rem;
  }
`;
export const FormContainerBanner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  flex-wrap: wrap;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  margin-bottom: 1rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    padding: 0.7rem;
  }
  @media (max-width: 520px) {
    padding: 0.4rem;
  }
`;
export const FormContainerBannerParent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  gap: 1rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    padding: 0.7rem;
  }
  @media (max-width: 520px) {
    padding: 0.4rem;
  }
`;
export const BannerImageBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 9px 0;
  position: relative;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 4px;
  }

  .edit-icon {
    height: 30px !important;
    width: 30px !important;
    object-fit: contain !important;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -0.7rem;
    margin-right: -1px;
  }
  .cross-icon {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain !important;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0rem;
    background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
    padding: 2px;
    border-radius: 50%;
  }

  span {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -0.7rem;
    /* margin-right: -1px; */
    background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
    border-radius: 50%;
  }
  i {
    /* margin-left: 0.5rem; */
    font-size: 1rem;
    color: ${(props) => (props.themeColor ? "white" : "black")};
  }
`;
export const FormContainers = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  padding: 0.7rem;
  margin-bottom: 1rem;
  gap: 0.5rem;

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 520px) {
    padding: 0.4rem;
  }
`;
export const BannerCard = styled.div`
  width: 18.8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  color: #edeef1;
  padding: 10px;
  background-color: transparent;
  text-transform: none !important;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  @media (max-width: 1383px) {
    width: 18.7%;
  }
  @media (max-width: 1301px) {
    width: 18.6%;
  }
  @media (max-width: 1230px) {
    width: 18.5%;
  }
  @media (max-width: 1205px) {
    width: 23.5%;
  }
  @media (max-width: 1091px) {
    width: 31%;
  }
  @media (max-width: 820px) {
    width: 40%;
  }
  @media (max-width: 769px) {
    width: 48%;
  }
  @media (max-width: 435px) {
    width: 47%;
  }
`;
export const BannerImage = styled.div`
  border-radius: 4px;
  width: 100%;
  /* width: 20%; */

  img {
    height: 160px;
    width: 100%;
    object-fit: center;
    border-radius: 4px;
  }
  @media (max-width: 769px) {
    img {
      height: 240px;
    }
  }
  @media (max-width: 600px) {
    img {
      height: 200px;
    }
  }
  @media (max-width: 460px) {
    img {
      height: 160px;
    }
  }
  @media (max-width: 402px) {
    img {
      height: 132px;
    }
  }
  @media (max-width: 359px) {
    img {
      height: 118px;
    }
  }
  @media (max-width: 340px) {
    img {
      height: 100px;
    }
  }
`;
export const BannerRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8rem;
`;
export const TitleBox = styled.div`
  width: 50%;
  /* display: flex; */
  /* justify-content: left;
  align-items: flex-start; */
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TitleBoxs = styled.div`
  /* display: flex; */
  /* justify-content: left;
  align-items: flex-start; */
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ActionButtons = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.8rem;

  @media (max-width: 769px) {
    display: none;
  }
`;
export const HeadButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1.3rem 0;
`;
export const HeadButtonGroups = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.7rem 0;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none !important;
  }
`;
export const HeadComButton = styled.div`
  background-color: ${(props) =>
    props.activeColor
      ? props.themeColor
        ? "#F6591C"
        : "#ffffff"
      : "transparent"};
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.8rem;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 500;
  border: ${(props) =>
    props.activeColor
      ? props.themeColor
        ? "1px solid #F6591C"
        : "1px solid #edeef1"
      : "1px solid transparent"};
  color: ${(props) =>
    props.activeColor ? (props.themeColor ? "#ffffff" : "#F6591C") : "#8A94A6"};

  @media (max-width: 552px) {
    /* width: 550px; */
  }
`;

export const MultiHeadingRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};

  /* padding-bottom: 10px; */
  margin-bottom: 1.6rem;
  overflow-x: scroll;

  @media (max-width: 360px) {
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
`;
export const HeadingBox = styled.div`
  color: ${(props) =>
    props.activeColor ? "#F6591C" : props.themeColor ? "#94969C" : "#667085"};
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 1rem;
  padding-bottom: 18px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.activeColor ? "2px solid #F6591C" : "2px solid transparent"};

  @media (max-width: 360px) {
    padding-bottom: 12px;
    /* width: 33.3%; */
  }
`;
export const MultiFormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const MultiFormContainerMap = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const MultiFormEpisodeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const AddButton = styled.button`
  font-size: 1rem;
  color: black;
  background-color: yellow;
`;
export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.ceNter ? "center" : "end")};
  border-top: ${(props) =>
    props.isBorder
      ? props.themeColor
        ? "1px solid #333741"
        : "1px solid #d7dae0"
      : "1px solid transparent"};
  padding-top: ${(props) => (props.isBorder ? "1rem" : "0")};
  margin-top: ${(props) => (props.isBorder ? "0.8rem" : "0")};
  align-items: center;
`;
export const ButtonRowBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.betWeen ? "space-between" : "end")};
  border-top: ${(props) =>
    props.isBorder
      ? props.themeColor
        ? "1px solid #333741"
        : "1px solid #d7dae0"
      : "1px solid transparent"};
  padding-top: ${(props) => (props.isBorder ? "1rem" : "0")};
  margin-top: ${(props) => (props.isBorder ? "0.2rem" : "0")};
  align-items: center;
`;
export const AddQuiz = styled.div`
  color: #f6591c;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const AddQuizs = styled.div`
  width: 100%;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  /* padding-left: 1.5rem; */
  margin-top: 1rem;

  button {
    outline: none;
    border: none;
    background-color: transparent;
    color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

    font-size: 0.8rem;
    font-weight: 500;
  }

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const AddQuizsComit = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  /* padding-left: 1.5rem; */

  button {
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: ${(props) => (props.activeColor ? "#8A94A6" : "#F05D24")};

    font-size: 0.8rem;
    font-weight: 500;
  }

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const AddQuizsDropDown = styled.div`
  width: 100%;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  text-align: center;

  button {
    outline: none;
    border: none;
    background-color: transparent;
  }

  img {
    height: 9px !important;
    width: 9px !important;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const AddQuizsDropDowns = styled.div`
  width: 100%;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  text-align: center;

  button {
    outline: none;
    border: none;
    background-color: transparent;
  }

  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const AddQuizsCourses = styled.div`
  width: 100%;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  padding-left: 1.5rem;
  margin-top: 1rem;

  button {
    outline: none;
    border: none;
    background-color: transparent;
  }

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    display: none;
  }
`;
export const AddQuizsCoursesResp = styled.div`
  width: 100%;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  padding-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  button {
    outline: none;
    border: none;
    background-color: transparent;
  }

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }
  /* @media (max-width: 769px) {
    display: flex;
  } */
`;
export const DeleteRowBorder = styled.div`
  width: 100%;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  padding-top: 0.8rem;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};

  button {
    border: none;
    outline: none;
    background-color: transparent;
  }
  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const DeleteRowBorderss = styled.div`
  width: 100%;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1rem;
  padding-top: 0.8rem;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};

  .momo {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
  }
  button {
    border: none;
    outline: none;
    background-color: transparent;
  }
  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const BinIcon = styled.img`
  height: 15px !important;
  width: 15px !important;
  object-fit: contain;
`;
export const DeleteRowBorders = styled.div`
  width: 100%;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  padding-top: 0.8rem;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};

  button {
    border: none;
    outline: none;
    background-color: transparent;
    color: #8a94a6;
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    flex-direction: column;
    align-items: end;
  }
`;
export const FeedbackRow = styled.div`
  width: 100%;
  display: flex;
  border: ${(props) =>
    props.activeColor
      ? props.themeColor
        ? "1px solid #F05D24"
        : "1px solid #FBA976"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #edeef1"};
  border-radius: 8px;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;
export const ComFeedbackBox = styled.div`
  width: ${(props) => (props.wiDTh ? props.wiDTh : "16%")};
  display: flex;
  flex-direction: column;

  @media (max-width: 1096px) {
    width: 30%;
    margin-bottom: 10px;
  }
  @media (max-width: 840px) {
    width: 50%;
    margin-bottom: 10px;
  }
`;
export const ComFeedbackRow = styled.div`
  width: ${(props) => (props.wiDTh ? props.wiDTh : "16.6%")};
  display: flex;
  align-items: center;
  gap: 1rem;
  @media (max-width: 660px) {
    width: auto;
  }
`;
export const ComFeedbackRowss = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`;
export const ComFeedbackRowssMaster = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
`;
export const ComFeedbackMaster = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 376px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ComFeedbackRowssGroup = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
  margin-top: 1rem;
`;
export const ComFeedbackRowssLang = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
`;
export const ComFeedbackRowssUser = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
`;
export const ComFeedbackRowsBorder = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
  @media (max-width: 660px) {
    width: auto;
  }
`;
export const ComFeedbackRows = styled.div`
  width: 16.6%;
  display: flex;
  align-items: center;
  @media (max-width: 841px) {
    width: 50%;
  }
`;
export const FeedbackTitle = styled.div`
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-size: 0.8rem;
  font-weight: 500;
`;
export const FeedbackOrangeText = styled.div`
  color: ${(props) =>
    props.activeColor ? (props.themeColor ? "#F05D24" : "#f6591c") : "#667085"};
  font-size: ${(props) => (props.fontSizes ? props.fontSizes : "0.8rem")};
  font-weight: 500;
  text-transform: capitalize;

  @media (max-width: 889px) {
    display: none;
  }
`;
export const FeedbackOrangeTexts = styled.div`
  color: ${(props) =>
    props.activeColor
      ? props.themeColor
        ? "#F05D24"
        : "#F05D24"
      : props.themeColor
      ? "#94969C"
      : "#667085"};
  font-size: ${(props) => (props.fontSizes ? props.fontSizes : "0.8rem")};
  font-weight: 500;
  text-transform: capitalize;
`;
export const FeedbackOrangeTextUpl = styled.div`
  color: ${(props) =>
    props.activeColor ? (props.themeColor ? "#CECFD2" : "#f6591c") : "#667085"};
  font-size: ${(props) => (props.fontSizes ? props.fontSizes : "0.8rem")};
  font-weight: 500;
  text-transform: capitalize;
`;
export const FeedbackOrangeTextss = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-size: ${(props) => (props.fontSizes ? props.fontSizes : "0.8rem")};
  font-weight: 500;
  text-transform: capitalize;
  @media (max-width: 769px) {
    width: 100%;
  }
`;
export const FeedbackCheckBox = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 769px) {
    width: 100%;
    margin-top: 0.5rem;
  }
`;
export const CheckBox = styled.div`
  cursor: pointer;
  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
    margin-left: 10px;
  }
`;
export const CheckBoxs = styled.div`
  cursor: pointer;
  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
    margin-right: 10px;
  }
`;
export const FeedbackDesc = styled.div`
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  font-weight: 500;
  font-size: 0.9rem;
  margin-top: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.5rem;
`;
export const DefaultBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
`;
export const DefaultBoxBorder = styled.div`
  width: 100%;
  display: flex;
  /* margin-top: 1rem; */
  /* margin-bottom: 0.5rem; */
  align-items: center;
  padding: 1rem 0;
  /* border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"}; */
`;
export const ErrorContainer = styled.div`
  width: 100%;
  text-align: left;

  p {
    font-size: 0.8rem;
  }
`;
export const DefaultBoxAss = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.isBorder
      ? props.themeColor
        ? "1px solid #333741"
        : "1px solid #EDEEF1"
      : ""};
  padding-bottom: ${(props) => (props.isBorder ? "0.7rem" : "0")};
`;
export const VideoBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #EDEEF1"};
  padding: 1rem 0;
  gap: 1rem;
`;
export const DefaultBoxQuiz = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
export const DefaultText = styled.div`
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  font-weight: 600;
`;
export const DefaultTextClient = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;

  p {
    font-size: 1rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    font-weight: 500;
  }
`;
export const DefaultToggle = styled.div`
  margin-left: 10px;
`;
export const AnswerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  margin: 0.8rem 0;

  @media (max-width: 769px) {
    padding-bottom: 1rem;
  }
`;
export const AnswerRowRole = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  margin: 0.8rem 0;
`;
export const PermissionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;
export const PermissionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  padding-bottom: 5px;
`;
export const ModuleName = styled.div`
  width: 30%;
  display: flex;

  p {
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  }
`;
export const ModuleCheck = styled.div`
  width: 17.7%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0.3rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  }
`;
export const AnswerRowSur = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  margin: 0.8rem 0;
`;
export const AnswerRowInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  padding-bottom: 0.4rem;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InputLableSur = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InputLableClient = styled.label`
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: ${(props) =>
    props.themeColor ? "#CECFD2 !important" : "#363a44 !important"};
  padding-bottom: 0.5rem;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InputLableCheck = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  padding-bottom: 0.5rem;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const OptionalText = styled.label`
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  padding-bottom: 0.5rem;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const OptionRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
export const DragInputRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
export const DragInputRowFeed = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;
export const DragInputRowOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
`;
export const DragIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    height: 20px;
    width: 10px;
    object-fit: contain;
  }
`;
export const DelEditCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
export const DelIcon = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  /* padding: 1.3rem 0; */

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 5px;
  }
  img {
    height: 18px !important;
    width: 18px !important;
    object-fit: contain;
  }

  @media (max-width: 769px) {
    /* flex-direction: column; */
    /* padding: 10px 0; */
  }
`;
export const DelIconEpisode = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 1.3rem 0;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 5px;
  }
  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
  }

  @media (max-width: 769px) {
    /* flex-direction: column; */
    /* padding: 10px 0; */
  }
`;
export const DelIconss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  padding: 1.3rem 0;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }

  @media (max-width: 769px) {
    flex-direction: column;
    padding: 10px 0;
  }
`;
export const DelIcons = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  padding: 1.3rem 0;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
`;
export const DelOptionsIcon = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  /* padding: 1.3rem 0; */
  margin: 0 0.5rem;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }
  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
  }
`;
export const BottomGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #ffffff; */
  padding: 1rem;
`;
export const AddQuestionButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${(props) =>
      props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
    background-color: #f6f7f9;
    text-align: center;
    outline: none;
    border-radius: 6px;
    background-color: transparent;
    color: #8a94a6;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 1rem 0;
    cursor: pointer;
  }

  img {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    object-fit: contain;
  }
`;
export const FileRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  z-index: 0;
`;
export const ImportantMsg = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    height: 15px !important;
    width: 15px !important;
    object-fit: contain;
  }
  p {
    font-size: 0.7rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    font-weight: 500;
  }
`;
export const FileRowEpisode = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  gap: 1rem;
`;
export const FileRowEpisodeVideo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  position: relative;
  gap: 1rem;
`;
export const FileRowDrag = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
`;
export const FileBox = styled.div`
  width: ${(props) => (props.widTh ? props.widTh : "100%")};
  display: flex;
  /* align-items: center; */
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 0.8rem 1rem;
  overflow: hidden;
  position: relative;

  /* Inner progress bar styling */
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => props.progressMo}%;
    background-color: ${(props) => (props.themeColor ? "#1F242F" : "#F6F7F9")};
    /* background-color: ${(props) => {
      // Set the color based on the progressMo
      if (props.progressMo < 50) {
        return "#F6F7F9"; // red
      } else if (props.progressMo < 75) {
        return "#F6F7F9"; // yellow
      } else {
        return "#F6F7F9"; // green
      }
    }}; */
    z-index: 0;
    transition: width 0.3s ease;
    border-radius: 8px; // To maintain the border radius of the FileBox
  }
  /* Additional content should be above the progress bar */
  > * {
    position: relative;
    z-index: 1;
  }

  img {
    height: 40px;
    width: 40px;
    margin: 0 !important;
    object-fit: contain;
  }
`;
export const FileBoxEpisode = styled.div`
  width: ${(props) => (props.widTh ? props.widTh : "100%")};
  display: flex;
  align-items: flex-start;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 0.8rem 1rem;
  overflow: hidden;
  position: relative;

  /* Inner progress bar styling */
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => props.progressMo}%;
    background-color: ${(props) => (props.themeColor ? "#1F242F" : "#F6F7F9")};
    /* background-color: ${(props) => {
      // Set the color based on the progressMo
      if (props.progressMo < 50) {
        return "#F6F7F9"; // red
      } else if (props.progressMo < 75) {
        return "#F6F7F9"; // yellow
      } else {
        return "#F6F7F9"; // green
      }
    }}; */
    z-index: 0;
    transition: width 0.3s ease;
    border-radius: 8px; // To maintain the border radius of the FileBox
  }
  /* Additional content should be above the progress bar */
  > * {
    position: relative;
    z-index: 1;
  }

  img {
    height: 40px;
    width: 40px;
    margin: 0 !important;
    object-fit: contain;
  }

  @media (max-width: 1300px) {
    width: 90%;
  }
  @media (max-width: 992px) {
    width: 87%;
  }
  @media (max-width: 818px) {
    width: 83%;
  }
  @media (max-width: 769px) {
    width: 85%;
  }
  @media (max-width: 528px) {
    width: 82%;
  }
  @media (max-width: 412px) {
    width: 80%;
  }
  @media (max-width: 382px) {
    width: 75%;
  }
`;
export const FileBoxs = styled.div`
  width: ${(props) => (props.widTh ? props.widTh : "100%")};
  display: flex;
  align-items: center;
  border: ${(props) =>
    props.error
      ? "1px solid red"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 0.8rem 1rem;
  overflow: hidden;
  position: relative;

  /* Inner progress bar styling */
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => props.progressMo}%;
    background-color: ${(props) => (props.themeColor ? "#1F242F" : "#F6F7F9")};
    // Set the color based on the progressMo
    // if (props.progressMo < 50) {
    //   return props.themeColor ? "#1F242F" : "#F6F7F9"; // red
    // } else if (props.progressMo < 75) {
    //   return props.themeColor ? "#1F242F" : "#F6F7F9"; // yellow
    // } else {
    //   return props.themeColor ? "#1F242F" : "#F6F7F9"; // green
    // }
    /* }}; */
    z-index: 0;
    transition: width 0.3s ease;
    border-radius: 8px; // To maintain the border radius of the FileBox
  }
  /* Additional content should be above the progress bar */
  > * {
    position: relative;
    z-index: 1;
  }

  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }

  @media (max-width: 1030px) {
    width: 90%;
  }
  @media (max-width: 834px) {
    width: 87%;
  }
  @media (max-width: 769px) {
    width: 90%;
  }
  @media (max-width: 549px) {
    width: 87%;
  }
  @media (max-width: 446px) {
    width: 83%;
  }
`;
export const InnerMediaBox = styled.div`
  width: 50%;
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  @media (max-width: 660px) {
    width: 90%;
  }
`;
export const ContentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
export const FileContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  text-transform: capitalize;
  gap: 2px;
  .head {
    font-weight: 500;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    width: 90%;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    text-transform: none;
  }
  .heads {
    font-weight: 500;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    text-transform: none;
  }
  .desc {
    font-weight: 400;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;
    text-overflow: ellipsis;
  }
  .row-text {
    font-weight: 400;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    text-transform: none;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 0.3rem;
  }
  .row-texts {
    font-weight: 400;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;
    text-overflow: ellipsis;
  }
  span {
    color: #17b26a;
  }

  @media (max-width: 532px) {
    .head {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const FileContentBoxTwo = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  text-transform: capitalize;
  gap: 2px;
  .head {
    font-weight: 500;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    width: 90%;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    text-transform: none;
  }
  .heads {
    font-weight: 500;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    text-transform: none;
  }
  .desc {
    font-weight: 400;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;
    text-overflow: ellipsis;
  }
  .row-text {
    font-weight: 400;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    text-transform: none;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 0.3rem;
  }
  .row-texts {
    font-weight: 400;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;
    text-overflow: ellipsis;
  }
  span {
    color: #17b26a;
  }

  @media (max-width: 532px) {
    .head {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const CircleRoll = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-self: start;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
  /* margin-right: 1rem; */

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
    color: #f05d24;
  }

  p {
    font-size: 0.8rem;
    font-weight: 400;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  }
`;
export const FileContentBoxCerti = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  text-transform: capitalize;
  gap: 2px;
  .head {
    font-weight: 600;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    width: 90%;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  .heads {
    font-weight: 500;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  .desc {
    font-weight: 400;
    font-size: 0.7rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .row-text {
    font-weight: 400;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 0.3rem;
  }
  .row-texts {
    font-weight: 400;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    color: #17b26a;
  }

  @media (max-width: 532px) {
    .head {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const FeildForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

// Drag
export const InnerDrag = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const InnerColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const DragInnerColumn = styled.div`
  height: 100%;
  width: 93%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const DragInnerColumnSmall = styled.div`
  height: 100%;
  width: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const DragBox = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
`;
export const DeleteBox = styled.div`
  height: 100%;
  width: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CircleBox = styled.div`
  width: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RadioBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 1rem 0px 0.6rem;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
  }
`;
export const InputLabless = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  span {
    font-weight: 400;
  }
  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InputBadge = styled.label`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  text-transform: capitalize;

  span {
    font-weight: 400;
  }
  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InputBadgeCerti = styled.label`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  text-transform: capitalize;

  span {
    font-weight: 400;
  }
  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const RowTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const RowTitleBadge = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
  /* padding-bottom: 0.3rem; */
  margin-bottom: 1rem;
`;
export const RowTitleCerti = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
  padding-bottom: 0.7rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;
export const OrangeTemplate = styled.a`
  color: #f6591c;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  img {
    height: 14px;
    width: 14px;
    object-fit: contain;
    margin-right: 7px;
  }
`;
export const TimeStamp = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};

  .common-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: #363a44;
  }

  @media (max-width: 769px) {
    justify-content: end;
    width: 100%;
  }
`;
export const TimeStampRow = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  @media (max-width: 769px) {
    justify-content: end;
    width: 100%;
    margin-top: 0.5rem;
  }
`;
export const ComBorder = styled.div`
  height: 22px;
  width: 1px;
  display: none;
  background-color: ${(props) => (props.themeColor ? "#333741" : "#D7DAE0")};
  @media (max-width: 769px) {
    display: flex;
  }
`;
export const TimeInputBox = styled.div`
  /* width: 10%; */
  display: flex;
  margin: 0 7px;

  input {
    border: ${(props) => (props.error ? "1px solid red" : "1px solid #D7DAE0")};
    border-radius: 10px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    height: auto;
    padding: 12px 10px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    outline: none;
    background-color: ${(props) => (props.themeColor ? "#161b26" : "#ffffff")};
  }

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const InnerCourseCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ImageContentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainDarkContainer = styled.div`
  width: 90%;
  margin: 4rem auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: black;
  border-radius: 0.8rem;

  @media (max-width: 769px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const SectionRowCourse = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  @media (max-width: 856px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
`;
export const SectionRowCourseColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 1rem 0; */
`;
export const SectionRowCourseColumns = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  margin: 15px 0;

  @media (max-width: 856px) {
    display: flex;
  }
`;
export const InnerSectionRowCourse = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 856px) {
    display: none;
  }
`;
export const ComColumn = styled.div`
  width: 17.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;
export const ComColumns = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;
export const InnerSectionRowCourses = styled.div`
  width: 100%;
  display: none;
  justify-content: space-between;

  @media (max-width: 856px) {
    display: flex;
  }
`;
export const SectionOne = styled.div`
  width: 100%;
`;

export const SectionTwo = styled.div`
  width: 100%;
`;

export const SectionThree = styled.div`
  width: 100%;
`;

export const SectionFour = styled.div`
  width: 100%;
`;

export const SectionFive = styled.div`
  width: 100%;
`;

export const SectionSix = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;

  @media (max-width: 856px) {
    width: 100%;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
    padding-bottom: 1rem;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#f05d24")};
  font-family: EB Garamond;
  font-size: 1.2rem;
  font-weight: 600;

  @media (max-width: 769px) {
    font-size: 1.4rem;
  }
  @media (max-width: 380px) {
    font-size: 1.3rem;
  }
`;
export const Titles = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#f05d24")};
  font-family: EB Garamond;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;

  span {
    color: #f6591d;
    border: 1px solid #f6591d;
    font-size: 0.8rem;
    padding: 7px 1rem;
    border-radius: 8px;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      height: 15px;
      width: 15px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  @media (max-width: 769px) {
    font-size: 1.4rem;
  }
`;

export const DarkTitle = styled.div`
  width: 100%;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const InnerDetails = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#3d4350")};
  font-weight: 500;
  font-size: 0.8rem;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const InnerDetailCatgory = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#464C5E")};
  font-weight: 400;
  font-size: 0.8rem;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const InnerDetail = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#3d4350")};
  font-weight: 500;
  font-size: 0.8rem;
  margin-top: 5px;
  line-height: 18px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 769px) {
    font-size: 1rem;
  }
  @media (max-width: 380px) {
    font-size: 0.8rem;
  }
`;

export const InnerDetailDark = styled.div`
  width: 100%;
  color: #fff;
  font-weight: 300;
`;

export const InnerContents = styled.div`
  width: 100%;
  color: #3d4350;
  font-size: 1rem;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  @media (max-width: 856px) {
    width: 50%;
  }
`;
export const InnerContent = styled.div`
  /* width: 19.5%; */
  color: #3d4350;
  font-size: 1rem;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  @media (max-width: 856px) {
    width: 50%;
  }
`;
export const InnerContentDetail = styled.div`
  /* width: 19.5%; */
  color: #3d4350;
  font-size: 1rem;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  @media (max-width: 856px) {
    width: 85%;
  }
`;
export const InnerContentCat = styled.div`
  /* width: 19.5%; */
  color: #3d4350;
  font-size: 1rem;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  @media (max-width: 856px) {
    width: 100%;
  }
`;
export const InnerContentResp = styled.div`
  /* width: 19.5%; */
  color: #3d4350;
  font-size: 1rem;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  @media (max-width: 856px) {
    width: 50%;
  }
  @media (max-width: 7696px) {
    width: auto;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#94969C" : "#3d4350")};
  font-size: 0.8rem;
  margin-bottom: 5px;
  font-weight: 400;
  @media (max-width: 380px) {
    font-size: 0.7rem;
  }
`;

export const MiniTitleDark = styled.div`
  width: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
`;

export const SubTitleDark = styled.div`
  width: 100%;
  color: #fff;
  font-size: 1rem;
  margin-bottom: 5px;
  font-weight: 300;
`;

export const MiniTitle = styled.div`
  width: 100%;
  color: #3d4350;
  font-weight: 500;
  font-size: 1rem;
`;

export const AddItem = styled.div`
  width: 100%;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#3d4350")};
  @media (max-width: 856px) {
    text-align: center;
    font-size: 1.7rem;
  }
`;

export const AddItemDark = styled.div`
  width: 100%;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 300;
`;
export const SelectBox = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;
export const OrangeButtonText = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#F05D24")};
  font-weight: 600;
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;

  button {
    outline: none;
    background-color: transparent;
    border: none;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#F05D24")};
    font-weight: 600;
    font-size: 0.8rem;
    display: flex;

    align-items: center;
  }
  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
`;
export const MandatoryBox = styled.div`
  width: 100%;

  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  padding-top: 0.8rem;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
`;
export const DeleteButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#8A94A6")};
  display: flex;
  align-items: center;
  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const MandatoryButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#8A94A6")};
  display: flex;
  align-items: center;
  border-right: ${(props) =>
    props.isBorder ? "1px solid #d7dae0" : "1px solid transparent"};
  margin-right: 10px;

  span {
    margin: 0 10px;
  }
`;

export const RatingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.1rem;

  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }
`;
export const TrailerCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"}; */
  border-radius: 8px;
  flex-direction: column;
  gap: 1rem;
`;
export const CommonCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  border-radius: 8px;
  flex-direction: column;
`;
// Add /Edit Form
export const AddEditRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin: 0.4rem 0;
`;
export const ComBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.47rem;
  cursor: pointer;

  p {
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
    font-size: 0.8rem;
    font-weight: 500;
  }

  img {
    height: 17px;
    width: 17px;
    object-fit: contain;
  }
  .plus-icon {
    height: 13px;
    width: 13px;
    object-fit: contain;
  }
`;
// Badges Box
export const BadgesBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 1.5rem;
`;
export const BadgesBoxCerti = styled.div`
  height: 185px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  /* border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"}; */
  border-radius: 8px;
  padding: 1.5rem;
  transform: scale(0.3);
  margin-left: -24rem;

  @media (max-width: 1423px) {
    margin-left: -22rem;
  }
  @media (max-width: 1343px) {
    margin-left: -30rem;
    width: 200%;
  }
  @media (max-width: 1204px) {
    margin-left: -28rem;
  }
  @media (max-width: 1145px) {
    margin-left: -26rem;
  }
  @media (max-width: 1063px) {
    margin-left: -24rem;
  }
  @media (max-width: 1010px) {
    margin-left: -22rem;
  }
  @media (max-width: 947px) {
    margin-left: -20rem;
  }
  @media (max-width: 887px) {
    margin-left: -18rem;
  }
  @media (max-width: 833px) {
    margin-left: -16rem;
  }
  @media (max-width: 769px) {
    margin-left: -22rem;
  }
  @media (max-width: 695px) {
    margin-left: -20rem;
  }
  @media (max-width: 628px) {
    margin-left: -18rem;
  }
  @media (max-width: 573px) {
    margin-left: -16rem;
  }
  @media (max-width: 490px) {
    margin-left: -8rem;
  }
  @media (max-width: 395px) {
    margin-left: 0rem;
    width: 300%;
  }
`;
export const BadgeContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  border: ${(props) =>
    props.themeColor ? "2px solid #333741" : "2px solid #EDEEF1"};
  border-radius: 8px;
  padding: 0.7rem;
`;
export const BadgeImage = styled.img`
  height: 170px !important;
  width: 260px !important;
  margin: 0 !important;
  object-fit: contain;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #EDEEF1"};
  border-radius: 4px;
`;
export const BadgeRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
`;
export const ComBadge = styled.div`
  p {
    font-size: 0.8rem;
    font-weight: 400;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
  }

  img {
    height: 17px;
    width: 17px;
    object-fit: contain;
  }
`;
export const ComBadgeCerti = styled.div`
  p {
    font-size: 2.8rem;
    font-weight: 400;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
  }

  img {
    height: 45px;
    width: 45px;
    object-fit: contain;
    cursor: pointer;
  }
`;
export const GroupHeading = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-transform: capitalize;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#F05D24")};
  margin-bottom: 0.6rem;

  img {
    height: 11px !important;
    width: 11px !important;
    object-fit: contain;
    margin: 0 0.8rem;
  }
`;
export const AddQuizsOption = styled.div`
  width: 100px;
  color: ${(props) => (props.activeColor ? "#8A94A6" : "#f6591c")};

  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  /* padding-left: 1.5rem; */
  margin-top: 1rem;

  button {
    outline: none;
    border: none;
    background-color: transparent;
  }

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const OptionRowMomo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize !important;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
  }

  img {
    height: 10px !important;
    width: 10px !important;
    object-fit: contain;
  }
`;
export const EmailEditor = styled.div`
  width: 100%;
  background-color: white;
  background-color: ${(props) =>
    props.themeColor ? "transparent" : colorProvider.white};
  margin-top: 0.5rem;
  margin-bottom: 3rem;

  p {
    color: ${(props) =>
      props.themeColor ? "#94969C" : colorProvider.greyDark} !important;
  }
  svg {
    /* display: none; */
  }
  .quill {
    height: 150px; // Set the height you want for the editor
    width: 100%; // Set the width you want for the editor
    .ql-toolbar .ql-active {
      border: ${(props) =>
        props.themeColor
          ? "1px solid #333741"
          : "1px solid #D7DAE0"}; /* Light grey background for active buttons */
      border-radius: 5px;
    }

    .ql-toolbar .ql-active img {
      opacity: 0.7; /* Slightly lower opacity for the icon when active */
    }
    .ql-toolbar.ql-snow {
      border: ${(props) =>
        props.error
          ? `1px solid red`
          : props.themeColor
          ? `1px solid #333741`
          : commonStyles.com_b} !important;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border: ${(props) =>
        props.error
          ? `1px solid red`
          : props.themeColor
          ? `1px solid #333741`
          : commonStyles.com_b} !important;
    }
    .ql-snow .ql-picker-options {
      background-color: ${(props) =>
        props.themeColor
          ? colorProvider.darkBlue
          : colorProvider.white} !important;
    }
    .ql-container {
      height: 100%;
      border-radius: 0 0 8px 8px !important;
    }

    .ql-editor {
      min-height: 100%;
      padding: 10px !important;
      overflow-y: auto;
    }

    .ql-toolbar {
      border-radius: 8px 8px 0 0;
    }
    .ql-snow .ql-stroke {
      stroke: ${(props) =>
        props.themeColor ? "#94969C" : colorProvider.seeMore} !important;
    }

    .ql-snow .ql-fill,
    .ql-snow .ql-stroke.ql-fill {
      fill: ${(props) =>
        props.themeColor ? "#94969C" : colorProvider.seeMore} !important;
    }
    .ql-snow .ql-picker.ql-picker {
      border: ${(props) =>
        props.themeColor ? `1px solid #333741` : commonStyles.com_b} !important;
      border-radius: 8px;
      padding: 4px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) =>
        props.themeColor ? "#94969C" : colorProvider.greyDark} !important;

      svg {
        display: none;
      }

      .ql-picker-label::before {
        /* display: none; */
      }

      .ql-picker-label::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        background: url(${downArrowIcon}) no-repeat center center;
        background-size: contain;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.ql-expanded .ql-picker-label::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
      border-color: transparent;
    }
    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
      margin-right: 12px !important;
    }
    .ql-toolbar.ql-snow .ql-formats {
      margin-right: 0 !important;
    }
    .ql-editor.ql-blank::before {
      color: ${(props) => (props.themeColor ? "#0C111D" : "#000")} !important;
    }
    .ql-editor p {
      color: ${(props) => (props.themeColor ? "#fff" : "#000")} !important;
    }
  }
`;
export const LabelBox = styled.div`
  width: 20%;

  p {
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    font-weight: 500;
    font-size: 0.8rem;
  }
`;
export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};
  margin-bottom: 1rem;

  p {
    font-size: 1rem;
    font-weight: 600;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
  }
  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    cursor: pointer;
  }
`;
// Custom Table
export const BorderLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => (props.themeColor ? "#333741" : "#D7DAE0")};
  margin-top: 1.5rem;
`;
export const CheckboxContainer = styled.div`
  display: inline-block;
  padding: 8px 10px;
`;

export const ThCheckboxContainer = styled(CheckboxContainer)`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;
  padding-top: 0;
  display: flex;
`;
export const TableSection = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px;
  /* overflow-x: scroll; */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Table = styled.table`
  width: 100%;
  /* border-collapse: collapse; */
  color: #fff;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.85rem")};
  margin: 1.5rem 0;
  /* min-height: 500px; */
  overflow-y: auto;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #EDEEF1"};
  border-radius: 8px;
`;

export const Th = styled.th`
  background-color: ${(props) => (props.themeColor ? "#333741" : "#EDEEF1")};
  padding: 3px 15px;
  text-align: left;
  font-weight: 400;
  font-size: 0.7rem;
`;
export const ThStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
  text-transform: capitalize;
  /* gap:10px; */
`;

export const Td = styled.td`
  padding: 8px 15px;
  color: ${(props) =>
    props.themeColor ? "#CECFD2 !important" : "#464C5E !important"};
  height: 70px;
  border-bottom: ${(props) =>
    props.index
      ? "none"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #EDEEF1"};
`;

export const Tr = styled.tr`
  height: 40px;
`;
export const Thead = styled.thead`
  /* background-color: #000000; */
  height: 40px;
  color: ${(props) =>
    props.themeColor ? "#CECFD2 !important" : "#464C5E !important"};
  border-radius: 8px 8px 0 0;
`;
