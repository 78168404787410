import styled from "styled-components/macro";

export const SidebarContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0.5px solid;
  background-color: #ffffff;
  transition: width 2s ease;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border-color: ${(props) => (props.themeColor ? "#1F242F" : "#edeef1")};
  position: relative;
  overflow: auto;
  overflow: hidden !important;

  @media (max-width: 769px) {
    display: none;
  }
`;
export const SidebarContainerResp = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  display: none;
  justify-content: space-between;
  align-items: center;
  border-right: 0.5px solid;
  /* background-color: #ffffff; */
  transition: width 2s ease;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-color: ${(props) => (props.themeColor ? "#1F242F" : "#edeef1")};
  left: ${(props) => (props.sidebarIsOpen ? "" : "-100%")};
  /* transition: 100ms; */
  overflow: hidden !important;
  @media (max-width: 769px) {
    display: flex;
    left: ${(props) => (props.sidebarIsOpen ? "0" : "-100%")};
    z-index: 999;
  }
`;
export const InnerSidebar = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};

  @media (max-width: 769px) {
    width: 35%;
  }
  @media (max-width: 672px) {
    width: 40%;
  }
  @media (max-width: 561px) {
    width: 50%;
  }
  @media (max-width: 493px) {
    width: 60%;
  }
  @media (max-width: 412px) {
    width: 70%;
  }
  @media (max-width: 388px) {
    width: 80%;
  }
`;
export const InnerSidebarBlank = styled.div`
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 1rem;
  background-color: black;
  opacity: 0.3;

  @media (max-width: 620px) {
    width: 60%;
  }
  @media (max-width: 473px) {
    width: 50%;
  }
  @media (max-width: 373px) {
    width: 40%;
  }
`;
export const LogoListBox = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const LogoRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1.8rem 1.2rem 1rem;
  cursor: pointer;
  /* margin-bottom: 1rem; */
`;

export const HideLogo = styled.div`
  width: auto;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1rem 0 2rem;
`;

export const FrameLogoContainer = styled.div`
  width: 100%;

  img {
    height: 40px;
    width: 40px;
  }
`;

export const MainLogo = styled.div`
  height: 40px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 786px) {
    margin: 0;
  }
`;
export const ArrowLogo = styled.img`
  height: 13px;
  width: 13px;
  object-fit: contain;
`;
export const ArrowLogoResp = styled.img`
  height: 13px;
  width: 13px;
  object-fit: contain;
`;

export const ArrowLogo1 = styled.img`
  height: 13px;
  width: 13px;
  object-fit: contain;
`;

export const MenuRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.activeColor ? "#F6591C" : "#8b92a1")};
  background-color: ${(props) =>
    props.activeColor
      ? props.themeColor
        ? "#1F242F"
        : "#f6f7f9"
      : props.themeColor
      ? "#0C111D"
      : "#FFFFFF"};
  font-size: 0.8rem;
  font-weight: ${(props) => (props.activeColor ? "600" : "500")};
  /* margin-bottom: 0.8rem; */
  padding: 0.8rem 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 5px;

  img {
    height: 17px;
    width: 17px;
    margin-right: 10px;
    object-fit: contain;
  }

  .row-wise {
    display: flex;
  }
  .arrow-img {
    height: 14px;
    width: 14px;
    object-fit: contain;
  }

  /* :hover {
    background-color: ${(props) => (props.themeColor ? "#1F242F" : "#f6f7f9")};
  } */
`;
export const ActiveSub = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.activeColor ? "#F6591C" : "#8b92a1")};
  background-color: ${(props) =>
    props.activeColor
      ? props.themeColor
        ? "#1F242F"
        : "#f6f7f9"
      : props.themeColor
      ? "#0C111D"
      : "#FFFFFF"};
  font-size: 0.8rem;
  font-weight: ${(props) => (props.activeColor ? "600" : "500")};
  /* margin-bottom: 0.8rem; */
  padding: 0.6rem 1rem;
  border-radius: 6px;
  cursor: pointer;
`;
export const LogOutBox = styled.div`
  width: 100%;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  padding: 1.3rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #f6591c;
  font-size: 0.8rem;
  padding-left: 1.2rem;
  margin-bottom: 1rem;
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 0.7rem;
  }
`;
export const LogOutBoxResp = styled.div`
  width: 100%;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  padding: 1.3rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #f6591c;
  font-size: 0.8rem;
  padding-left: 1.2rem;
  margin-bottom: 1rem;
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 0.7rem;
  }

  .log-out {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-left: 1.4rem;
  }
`;
// Extra
export const SidebarList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 78vh;
  overflow-y: scroll;

  /* Set the default styles for the scrollbar */
  ::-webkit-scrollbar {
    width: 4px !important; /* Normal state width */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners for the thumb */
    width: 7px !important; /* Width increases to 7px on hover */
  }

  /* Increase the scrollbar width on hover */
  ::-webkit-scrollbar:hover {
    width: 7px !important; /* Width increases to 7px on hover */
  }

  /* Optional: Style adjustments for the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Optionally darken the thumb on hover */
    width: 7px !important; /* Width increases to 7px on hover */
  }

  /* Responsive behavior for devices with a max-width of 769px */
  @media (max-width: 769px) {
    ::-webkit-scrollbar {
      width: 0px; /* Hide scrollbar for smaller devices */
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent; /* Make the thumb transparent */
    }
  }
`;
export const MenuBox = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.7rem;
`;
export const SubMenuBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  transition: max-height 0.3s ease-in-out;
  /* margin-top: 5px; */
  max-height: ${({ isActive }) => (isActive ? "200px" : "0")};
`;
